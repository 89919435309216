@media (display-mode: browser) {
}

@media (display-mode: standalone) {
}

@media (prefers-color-scheme: light) {
}

@media (prefers-color-scheme: dark) {
}

@media (prefers-reduced-motion) {
}

:root {
  --primary-color: #FF4242;
  --black: #272727;
}

body {
  // match the user's default platform font
  font-size: 1em;
  font-family: -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  system-ui,
  Roboto,
  Oxygen-Sans,
  Ubuntu,
  Cantarell,
  "Helvetica Neue",
  sans-serif;

  accent-color: var(--primary-color);

  padding: 0;
  margin: 0;

  height: calc(100dvh - 50px);
  overflow: hidden;
}

main {
  padding: env(safe-area-inset-top, 0px) 0 env(safe-area-inset-bottom, 0px) 0;
  // padding: env(safe-area-inset-top, 0px) env(safe-area-inset-right, 0px) env(safe-area-inset-bottom, 0px) env(safe-area-inset-left, 0px);
}

pre {
  margin: 0;
}

.navigation {
  position: absolute;
  height: calc(50px + env(safe-area-inset-bottom, 0px));
  width: 100%;
  top: calc(100dvh - 50px - env(safe-area-inset-bottom, 0px));
  left: 0;

  background-color: var(--black);
  border-top: 2px solid var(--black);
  border-bottom: 2px solid var(--black);

  display: flex;
  flex-direction: row;
  gap: 2px;
}

.navigation-item {
  flex-grow: 1;

  background-color: var(--primary-color);
  color: white;
  font-size: 2em;

  padding: 4px;
  text-align: center;
  line-height: 42px;

  cursor: pointer;
  // Disable selection for UI elements
  user-select: none;
}

.navigation-item.selected {
  background-color: var(--black);
}

.content {
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.content-loader {
  height: calc(100dvh - 50px);
  max-width: 100%;

  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 2s ease infinite;

  color: white;
  font-size: 1.5em;
  line-height: 10em;
  text-align: center;
}

.content-error {
  height: 100dvh;
  width: 100%;

  background-color: var(--black);

  padding-top: 20dvh;
  color: white;
  font-size: 1.5em;
  text-align: center;
}

.content-user-name {
  padding-top: 1.5em;
  text-align: center;
  font-size: 1.2em;
  font-weight: bold;
  color: var(--primary-color);
}

.content-user-subscription {
  font-style: italic;
  padding: 0.5em;
  text-align: center;
}

.content-events h2 {
  text-align: center;
  font-size: 1.2em;
}

.content-events .nothing {
  text-align: center;
  color: var(--primary-color);
  font-weight: bold;
}

.content-events div {
  padding: 0.5em;
}

.content-events div > div:first-of-type {
  font-size: 1.2em;
  padding: 0;
}

.content-events div > div:last-of-type {
  font-size: 0.8em;
  font-style: italic;
  color: var(--black);
  padding: 0;
}

.content-auth h2 {
  text-align: center;
  font-size: 1.2em;
  margin-bottom: 0.2em;
}

.content-auth hr {
  margin: 1em 0;
  color: var(--black);
}

.content-auth .note {
  font-style: italic;
  color: var(--black);
  padding: 0.5em 0;
  font-size: 0.8em;
  text-align: center;
  display: block;
  width: 100%;
}

.content-auth .qr {
  margin: 1em 0;
}

.content-auth .qr h3 {
  font-style: italic;
  color: var(--black);
  text-align: center;
  margin: 0.2em;
}

.content-auth .qr img {
  max-width: 100%;
  max-height: 50dvh;
  margin: 0 auto;
  display: block;
}

.content-password-auth,
.content-web-auth {
  margin: 0 auto
}

.content-password-auth input,
.content-web-auth input {
  display: block;
  padding: 0.5em;
  margin: 0.5em auto;
  font-size: 1em;
  background-color: #FFFFFF;
  border-width: 1px;
  border-style: solid;
  border-color: #E7E7E7;
  border-radius: 2px;
  color: #3B3B3B;
  outline: none;
}

.content-auth button,
.content-auth button {
  color: white;
  background-color: var(--primary-color);
  border: 0;
  border-radius: 0.5em;
  line-height: 1.5;
  padding: 1em;
  cursor: pointer;
  margin: 0 auto;
  display: block;
}

.content-settings {
  padding: 0 0.5em;
}

.content-settings .error {
  color: var(--primary-color);
  font-size: 1.4em;
  font-weight: bold;
  text-align: center;
  padding-top: 20dvh;
}

.content-settings h2 {
  text-align: center;
  font-size: 1.2em;
}

.content-settings button {
  color: white;
  background-color: var(--primary-color);
  border: 0;
  border-radius: 0.5em;
  line-height: 1.5;
  padding: 1em;
  cursor: pointer;
  margin: 0 auto;
  display: block;
}

.content-settings .content-settings-push {
  text-align: center;
  border: 1px solid var(--black);
  border-radius: 4px;
  margin: 1em 0;
  padding: 0.5em;
}

.content-settings .content-settings-push div {
  padding: 0.5em;
}

.content-settings .content-settings-web-auth {
  border: 1px solid var(--black);
  border-radius: 4px;
  margin: 1em 0;
  padding: 0.5em;
}